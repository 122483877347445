import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { Paginator } from 'primeng/paginator';
import { AppSettings } from '../Constant/AppSettings';
import { ContentService } from '../services/content/content.service';
import { UtilsService } from '../services/utils/utils.service';
import Quill from 'quill';
// import ImageResize from 'quill-image-resize-module';

// Quill.register('modules/imageResize', ImageResize);
@Component({
  selector: 'app-how-to',
  templateUrl: './how-to.component.html',
  styleUrls: ['./how-to.component.css']
})
export class HowToComponent implements OnInit {
  items: MenuItem[] = [{ label: 'How To Guide' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  @ViewChild('p', { static: false }) paginator?: Paginator;
  menuItems: MenuItem[] = [];
  howToId = '';
  cols: any[] = [];
  howToList: any[] = [];
  disabled: boolean = false;
  isLoading: boolean = false;
  innerWidth: any;
  modules: any = {};
  //Paginator variable
  timer: any;
  pageSize: any = 0;
  currentPage: any = 1;
  totalCount: any = 0;
  searchInputText: string = '';
  //Dialog
  questionText: string = '';
  answerText: any = '';
  headerName: string = '';
  displayAddEditModal: boolean = false
  displayViewModal: boolean = false

  categories: any;
  filteredHowto: any;
  selectedCategory = 'All';

  searchKeyword: string = '';
  categoryText: string = '';

  constructor(
    public utils: UtilsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.cols = [
      { field: 'question', header: 'Question' },
    ]
    this.modules = {
      imageResize: {},
    }
    this.getAllHowto();
  }

  onCategoryChange(category: string) {
    this.selectedCategory = category;
    this.searchKeyword = '';
    this.filteredHowto = category === 'All' ? [...this.howToList] : this.howToList.filter(howto => howto.category === category);
  }

  performSearch(keyword: string) {
    if(keyword == null){
      this.getAllHowto();
    }else {
    this.searchKeyword = keyword;
    this.selectedCategory = 'All';
    const lowerKeyword = keyword.toLowerCase();
    this.filteredHowto = this.howToList.filter(
      howToList => howToList.question.toLowerCase().includes(lowerKeyword) || howToList.answer.toLowerCase().includes(lowerKeyword)
    );}
  }

  onSearchKeydown(event: KeyboardEvent, value: string): void {
    if(value.length === 1){
      this.getAllHowto();
    }
    if (event.key) {
      this.performSearch(value);
    }
  }

  getAllHowto() {
    this.showProgressBar()
    this.howToList = []
    this.contentService.getAllContent("HOWTO").subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('List All How to Response --->> ' + JSON.stringify(json));

        if (json.response.status == 'SUCCESS') {
          this.howToList = json.data;
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }

        const categoryStrings = ['All', ...new Set(this.howToList.map(howto => howto.category))]; // Extract unique categories
        this.filteredHowto = [...this.howToList];

        const sortedCategories = categoryStrings.filter(category => category !== 'All').sort();
        this.categories = [{ label: 'All', value: 'All' }, ...sortedCategories.map(category => ({
          label: category,
          value: category
        }))];
        
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  addHowTo() {
    this.showProgressBar()
    if (this.questionText == '') {
      this.showErrorMessage('Question field is empty.')
      this.hideProgressBar();
      return;
    }
    if (this.answerText == '') {
      this.showErrorMessage('Answer field is empty.')
      this.hideProgressBar();
      return;
    }
    const Data = {
      data: {
        question: this.questionText,
        answer: this.answerText,
        contentType: "HOW_TO"
      }
    };
    console.log("add data:", JSON.stringify(Data));
    this.contentService.addContent(Data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Add How To Post Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.showHideModalDialog('add_how_to')
          // this.listHowTo(this.currentPage, this.searchInputText)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  editHowTo() {
    this.showProgressBar()
    if (this.questionText == '') {
      this.showErrorMessage('Question field is empty.')
      this.hideProgressBar();
      return;
    }
    if (this.answerText == '') {
      this.showErrorMessage('Answer field is empty.')
      this.hideProgressBar();
      return;
    }
    const Data = {
      data: {
        question: this.questionText,
        answer: this.answerText,
        contentType: "HOW_TO"
      }
    };
    console.log("add data:", JSON.stringify(Data));
    this.contentService.editContent(Data, this.howToId).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Edit How To Post Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          this.showHideModalDialog('edit_how_to')
          // this.listHowTo(this.currentPage, this.searchInputText)
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  deleteHowTo(item: any) {
    // console.log('id:' + item + ', event:' + JSON.stringify(e))
    this.showProgressBar()
    this.contentService.deleteContent(item.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Delete How To Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage)
          // this.listHowTo(this.currentPage, this.searchInputText)
        } else {
          this.showErrorToastMsg(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }


  getHowTo(type: any, item: any) {
    switch (type) {
      case 'edit_how_to':
        this.showHideModalDialog('edit_how_to')
        break;
      case 'view_how_to':
        this.showHideModalDialog('view_how_to')
        break;
    }

    this.howToId = item.id;
    this.showProgressBar()
    this.contentService.getContent(item.id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get How To Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.questionText = json.data.question
          this.answerText = json.data.answer
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar()
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
        this.hideProgressBar()
      }
    );
  }

  confirmDeleteDialog(item: any) {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Do you want to delete this How To ?',
      header: 'Delete Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.deleteHowTo(item);
      },
    });
  }


  showHideModalDialog(type: string) {
    switch (type) {
      case 'edit_how_to':
        this.headerName = 'Edit How To';
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
      case 'add_how_to':
        this.clearFormData();
        this.headerName = 'Add How To';
        this.displayAddEditModal = !this.displayAddEditModal;
        break;
      case 'view_how_to':
        this.clearFormData();
        this.headerName = 'View How To';
        this.displayViewModal = !this.displayViewModal;
        break;
    }
  }

  clearFormData() {
    this.messageService.clear('errMsg');
    this.questionText = ''
    this.answerText = ''
  }

  resetHowToList() {
    this.selectedCategory = 'All';
    this.searchKeyword = '';
    this.getAllHowto();
  }

  onEnterSearchHowTo(event: any, searchValue: string) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.currentPage = 1;
      this.paginator?.changePage(0);
      // this.listHowTo(this.currentPage, searchValue)
    }, 900);
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'error',
      detail: msg,
    });
  }

  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showProgressBar() {
    this.messageService.clear('errMsg');
    this.isLoading = true;
    this.disabled = true;
  }

  showDialogMaximized(event: any, dialog: Dialog) {
    if (this.innerWidth <= 640) {
      dialog.maximized = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  paginate(event: any) {
    console.log(event)
    // this.listHowTo(event.page + 1, this.searchInputText)
  }

  get state(): string {
    return this.utils.state;
  }
}
