import { Component, Input, OnInit } from '@angular/core';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import { ChatRoomService } from '../services/chat-room/chat-room.service';
import { environment } from 'src/environments/environment.prod';
import { AppSettings } from '../Constant/AppSettings';
import { MessageService } from 'primeng/api';
import { ResizeEvent } from 'angular-resizable-element';
import { MessagesTemplateService } from '../services/messages-template/messages-template.service';

@Component({
  selector: 'app-phone-preview',
  templateUrl: './phone-preview.component.html',
  styleUrls: ['./phone-preview.component.css']
})
export class PhonePreviewComponent implements OnInit {

  @Input() messageType: string = "";
  @Input() headerShow: String = "";
  @Input() cropImage: string = "";
  @Input() getBackgroundColor: string = "";
  @Input() getBusinessName: string = "";
  @Input() getPhone: string = "";
  @Input() getTextColor: string = "";
  @Input() previewText: string = "";

  @Input() businessNameAlignment: string = "";
  @Input() businessNameSize: number = 14;
  @Input() businessNameBold: boolean = false;
  @Input() businessNameItalic: boolean = false;
  @Input() businessNameFont: string = "";
  @Input() businessNameUnderline: boolean = false;

  @Input() phoneNumberAlignment: string = "";
  @Input() phoneNumberSize: number = 14;
  @Input() phoneNumberBold: boolean = false;
  @Input() phoneNumberItalic: boolean = false;
  @Input() phoneNumberFont: string = "";
  @Input() phoneNumberColor: string = "";
  @Input() phoneNumberUnderline: boolean = false;

  businessNameGraphicProperty:any;
  businessNumberGraphicProperty:any;


  showPreviewText:string = "";
  bussinesInfo = CommonFunctions.getBusinessInfo();
  isLoading: boolean = false;
  disabled: boolean = true;
  profileUrl = '';
  backgroundColor: string = '';
  textColor: string = 'whitesmoke';
  style: any = {};
  businessName: string = "";
  phone: string = "";
  imageInputError: boolean = false;

  public childFunction(messageType:any, previewText:any): void {
    this.showPreview(messageType, previewText);
  }

  constructor(
    private chatRoomService: ChatRoomService,
    private messageService: MessageService,
    private messagesTemplateService: MessagesTemplateService
  ) { }

  ngOnInit(): void {
    this.getChatRoom();
  }

  getChatRoom() {
    this.showProgressBar();
    this.chatRoomService.getChatRoom().subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get chat room response --> ' + JSON.stringify(json.data));
        if (json.response.status == 'SUCCESS') {
          // this.setData(json.data)
          this.backgroundColor = json.data.backgroundColor;
          if(json.data.image == null){
            this.profileUrl = 'assets/images/picture.svg';
          }else{
            this.profileUrl = environment.IMAGE_BASE_URL + json.data.image;
          }
          this.textColor = json.data.textColor;
          this.businessName = json.data.name;
          this.phone = json.data.secondLine;
          this.businessNameGraphicProperty = json.data.businessNameGraphicProperty;
          this.businessNumberGraphicProperty = json.data.businessNumberGraphicProperty;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  showPreview(messageType:string, previewText:string){
    const Data = {
      data: {
        messageText: previewText
      }
    };

    this.showProgressBar();
    this.messagesTemplateService.renderPreTextMessageTemplate(Data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get ' + messageType + ' Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showPreviewText = json.data;
          this.messageType =  messageType;
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
        this.hideProgressBar();
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  showProgressBar() {
    this.isLoading = true;
    this.disabled = true;
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  onResizeEnd(event: ResizeEvent): void {
    this.style = {
      width: `${event.rectangle.width}px`,
      height: `${event.rectangle.height}px`
    };
  }

  fileFailedToLoad(event: any) {
    console.log(event)
    this.imageInputError = true;
    this.profileUrl = 'assets/images/picture.svg'
  }

  getBusinessNameStyle() {
    const properties = JSON.parse(this.businessNameGraphicProperty);
    return {
      'text-align': properties.alignment,
      'font-size.px': properties.size,
      'font-weight': properties.bold ? 'bold' : 'normal',
      'font-style': properties.italic ? 'italic' : 'normal',
      'text-decoration': properties.underline ? 'underline' : 'none',
      'font-family': properties.font,
    };
  }

  getBusinessNumberStyle() {
    const properties = JSON.parse(this.businessNumberGraphicProperty);
    return {
      'text-align': properties.alignment,
      'font-size.px': properties.size,
      'font-weight': properties.bold ? 'bold' : 'normal',
      'font-style': properties.italic ? 'italic' : 'normal',
      'text-decoration': properties.underline ? 'underline' : 'none',
      'font-family': properties.font,
      'color': properties.color || 'inherit',
    };
  }

}
