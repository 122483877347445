<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
        <app-message-credit-warning></app-message-credit-warning>
        <app-twillio-message></app-twillio-message>
        <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
            <div class="page_title">
                Business Calendar
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="p-mb-5">
            <p-card>
                <div style="display: flex; justify-content: flex-end; margin: 0 0 10px 0;">
                    <button *ngIf="getPrivilege('manage_appointment')"
                        class="p-button-raised p-button-sm" pButton pRipple
                        label="Earliest Appointments" style="font-size: 14px;margin-right: 4px;" 
                        (click)="showHideModalDialog('earliest_appoinment')"></button>
                    <button pButton pRipple label="Remove Test Appointment" icon="pi pi-trash" class="p-button-raised p-button-secondary p-button-sm" style="font-size: 14px;margin-right: 0.5rem;" (click)="showHideModalDialog('remove_test_appointment')"  [disabled]="bussinesInfo.state =='online'"></button>
                    <button *ngIf="getPrivilege('manage_appointment')"
                        class="p-button-raised p-button-sm" pButton pRipple
                        label="Client Appointments" style="font-size: 14px;margin-right: 4px;" 
                        (click)="showHideModalDialog('view_all_client_appoinment')"></button>
                    <button *ngIf="getPrivilege('manage_appointment')"
                        class="p-button-raised p-button-sm" pButton pRipple
                        label="Import" style="font-size: 14px;margin-right: 4px;" 
                        (click)="showHideModalDialog('import_client')"></button>
                    <button
                        *ngIf="getPrivilege('manage_appointment')"
                        class="p-button-raised p-button-sm"
                        pButton pRipple
                        [label]="showExportOptions ? 'Cancel' : 'Export'"
                        [ngClass]="{'p-button-danger': showExportOptions, 'p-button-raised p-button-sm': !showExportOptions}"
                        style="font-size: 14px; margin-right: 4px;"
                        (click)="toggleExportOptions()"
                    ></button>                   
                </div>
                <div *ngIf="showExportOptions" class="dropdown-menu-value" #contextMenus>
                    <button (click)="exportAppointments('future_appointments')">
                      Future Appointments
                    </button>
                    <button (click)="toggleDateRangePicker()" [ngClass]="{'selected-date-range': showDateRangePicker}">
                      Select Date Range
                    </button>
                  
                    <div *ngIf="showDateRangePicker" class="date-picker-container">
                      <label>Start Date</label>
                      <p-calendar [(ngModel)]="startDate" (ngModelChange)="onDateChangeExport($event,'start')" placeholder="Enter Start Date" [showIcon]="true"></p-calendar>

                      <label>End Date</label>
                      <p-calendar [(ngModel)]="endDate" (ngModelChange)="onDateChangeExport($event,'end')" placeholder="Enter End Date" [showIcon]="true"></p-calendar>
                      <button (click)="onDateRangeSelect()" style="margin-top: 5px;">Export</button>
                    </div>
                </div>
                <div class="p-mb-3 toolbar">
                    <div class="p-grid p-ai-center">
                        <div class="p-col p-md-6 p-lg-3 p-fluid">
                            <p-dropdown [options]="serviceTypeFilterList" [(ngModel)]="selectedServiceType"
                                optionLabel="value" optionValue="name" [showClear]="true"
                                (onChange)="onFilterValueChange($event)" [style]="{'font-size':'14px'}"
                                placeholder="Service Name">
                            </p-dropdown>
                        </div>
                        <div class="p-col p-md-6 p-lg-3 p-fluid">
                            <p-dropdown [options]="serviceProviderFilterList" [(ngModel)]="selectedServiceProvider"
                                optionLabel="value" optionValue="name" [showClear]="true"
                                (onChange)="onFilterValueChange($event)" [panelStyle]="{'font-size':'14px'}"
                                placeholder="Service Provider">
                            </p-dropdown>
                        </div>
                        <div class="p-col p-md-6 p-lg-6 p-jc-end d-none d-lg-flex d-xl-flex">
                            <!-- <button pButton pRipple label="Remove Test Appointment" icon="pi pi-trash" class="p-button-raised p-button-secondary p-button-sm" style="font-size: 14px;margin-right: 0.5rem;" (click)="showHideModalDialog('remove_test_appointment')"  [disabled]="bussinesInfo.state=='online'"></button>
                            <button *ngIf="getPrivilege('manage_appointment')" pButton pRipple label="Block Slot"
                                icon="pi pi-ban" class="p-button-raised p-button-secondary p-button-sm"
                                style="font-size: 14px;margin-right: 0.5rem;"
                                (click)="showHideModalDialog('add_block_slots')"
                                [disabled]="bussinesInfo.state=='offline'"></button> -->
                            <button *ngIf="getPrivilege('manage_appointment')" 
                            pButton 
                            pRipple
                            class="p-button-raised p-button-sm"
                            [label]="isAdding ? 'Cancel' : 'Add New Appointment'"
                            [ngClass]="{'p-button-danger': isAdding, 'p-button-raised p-button-sm': !isAdding}"
                            style="font-size: 14px; margin-right: 0.5rem" 
                            (click)="toggleButton()"></button>
                            <button *ngIf="getPrivilege('manage_appointment')" pButton pRipple
                            label="View Appointment" class="p-button-raised p-button-sm"
                            style="font-size: 14px;margin-right: 4px;" (click)="showHideModalDialog('view_all_appoinment')"></button>
                            <button *ngIf="getPrivilege('manage_appointment')"
                            class="p-button-raised p-button-sm" pButton pRipple
                            label="Print" style="font-size: 14px;margin-right: 4px;" 
                            (click)="printDiv()"></button>
                        </div>

                    </div>
                </div>
                <div *ngIf="getPrivilege('manage_appointment')" class="p-grid d-lg-none">
                    <div class="p-col p-d-flex p-jc-center">
                        <!-- <button pButton pRipple label="Block Slot" icon="pi pi-ban"
                            class="p-button-raised p-button-secondary p-button-sm"
                            style="font-size: 12px;margin-right: 0.5rem;"
                            (click)="showHideModalDialog('add_block_slots')"></button> -->
                        <!-- <button pButton pRipple *ngIf="isAdding" label="Add New Appointment" icon="pi pi-plus"
                            class="p-button-raised p-button-sm" style="font-size: 12px;"
                            (click)="showHideModalDialog('add_appointment')"></button> -->
                        <button pButton pRipple label="View Appointment"
                            class="p-button-raised p-button-sm" style="font-size: 12px;"
                            (click)="showHideModalDialog('view_all_appoinment')"></button>
                    </div>
                </div>
                <!-- <div class="p-grid">
                            <div class="p-col">
                                <button pButton pRipple label="Today" class="p-button-raised p-button-secondary"
                                    (click)="goToday()"></button>
                                <button pButton pRipple icon="pi pi-angle-left" [style]="{'margin-left': '.5em'}"
                                    class="p-button-raised" (click)="goPrev()"></button>
                                <button pButton pRipple icon="pi pi-angle-right" [style]="{'margin-left': '.2em'}"
                                    class="p-button-raised" (click)="goNext()"></button>
                            </div>
                        </div> -->
                <div *ngIf="isCalendarLoading" class="alignCenter">
                    <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                    </p-progressSpinner>
                </div>

                <div id="print-page" (contextmenu)="disableRightClick($event)">
                    <full-calendar #calendar *ngIf='calendarVisible' [options]='calendarOptions'>

                    </full-calendar>
                </div>

               
                <div #contextMenu class="menu">
                    <ul class="menu-options">
                        <!-- <li class="menu-option" (click)="openDialogFromMenu('add_block_slots')">Add Block Slot
                        </li> -->
                        <li *ngIf="isAdding && !isEditing" class="menu-option" (click)="openDialogFromMenu('add_new_appoinment')">Add Appointment
                        </li>
                        <li *ngIf="!isAdding" class="menu-option" (click)="showHideModalDialog('view_all_appoinment_day')">View Appointment
                        </li>
                        <li *ngIf="isAdding && isEditing" class="menu-option" (click)="showHideModalDialog('edit_appointment_slot')">Select Slot 
                        </li>
                    </ul>
                </div>
                <!-- <p-contextMenu #contextMenu [target]="rightMenu" appendTo="body" [model]="menuItems">
                        </p-contextMenu> -->
                <!-- <p-menu #menu [popup]="true" [model]="menuItems"></p-menu> -->
            </p-card>
        </div>

    </p-scrollPanel>
</div>


<!-- ADD/EDIT MODAL -->
<p-dialog #addDialog [header]="headerName" [(visible)]="displayAddEditModal" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" styleClass="p-fluid" [contentStyle]="{'overflow':'visible'}"
    (onShow)="showDialogMaximized($event,addDialog)">
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
    <p-messages key="checkMsg" [style]="{
        'font-size': '12px'
      }"></p-messages>
    <form role="form" #addAppointmentForm="ngForm" style="min-width: 60vw;">
        <div class="row">
            <div class="form-group col-md-4">
                <label>Service Type<span class="symbol required"></span></label>
                <p-dropdown name="serviceType" [(ngModel)]="serviceType" [options]="serviceTypeList" optionLabel="name"
                    optionValue="value" [filter]="true" [style]="{height: '38px','font-size':'14px'}"
                    placeholder="Select Service Type" [panelStyle]="{'font-size':'14px'}"
                    (onChange)="onServiceTypeChange($event,'modal')"></p-dropdown>
            </div>
            <div class="form-group col-md-4">
                <label>Service Provider<span class="symbol required"></span></label>
                <p-dropdown name="serviceProvider" [options]="serviceProviderList" [(ngModel)]="serviceProvider"
                    optionLabel="name" optionValue="value" [filter]="true" [style]="{height: '38px','font-size':'14px'}"
                    placeholder="Select Service Provider" [panelStyle]="{'font-size':'14px'}" (onChange)="onServiceProviderChange($event)"></p-dropdown>
            </div>
            <div class="form-group col-md-4">
                <label>Client<span class="symbol required"></span></label>
                <p-dropdown name="client" [options]="clients" [(ngModel)]="client" optionLabel="name"
                    optionValue="value" [filter]="true" [style]="{height: '38px','font-size':'14px'}"
                    placeholder="Select Client" [panelStyle]="{'font-size':'14px'}"></p-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-4">
                <label for="startTime">Appointment Start Date/Time<span class="symbol required"></span></label>
                <p-calendar [(ngModel)]="appointmentStartDateTime" name="appointmentStartDateTime" inputId="startTime"
                    [showTime]="true" hourFormat="12" [showButtonBar]="true" [showIcon]="true" [readonlyInput]="true"
                    placeholder="Start date/time" (onClose)="onDateChange($event,'add_appointment')" [disabledDates]="invalidDates"></p-calendar>

            </div>
            <div class="form-group col-md-4">
                <label for="endTime">Appointment End Date/Time<span class="symbol required"></span></label>
                <p-calendar [(ngModel)]="appointmentEndDateTime" name="appointmentEndDateTime" inputId="endTime"
                    [showTime]="true" hourFormat="12" [showButtonBar]="true" [showIcon]="true" [readonlyInput]="true"
                    placeholder="End date/time" (onClose)="onDateChange($event,'add_appointment')"></p-calendar>

            </div>

        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Special Instruction</label>
                <div style="position: relative;">
                    <textarea pInputTextarea type="text" class="form-control" name="specialInstruction"
                        placeholder="Enter Special Instruction..." [(ngModel)]="specialInstruction" maxlength="500">
                    </textarea>
                    <span class="remaning">{{specialInstruction?.length}}/500</span>
                </div>

            </div>
        </div>
    </form>

    <ng-template pTemplate="footer">
        <p-button *ngIf="headerName=='Add Appointment'" icon="pi pi-save" label="Save"
            (click)="confirmAddEditDialog('add_appointment')" [disabled]="checkDisabled">
        </p-button>
        <p-button *ngIf="headerName=='Edit Appointment'" icon="pi pi-save" label="Save"
            (click)="confirmAddEditDialog('edit_appointment')" [disabled]="checkDisabled">
        </p-button>
    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>

<!-- VIEW ALL APPOINTMENT MODAL -->
<p-dialog #addDialog [header]="headerName" [(visible)]="visibleAllAppointment" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" styleClass="p-fluid" [contentStyle]="{'overflow':'visible'}" [style]="{'overflow':'auto', width: '80vw'}"
    (onShow)="showDialogMaximized($event,addDialog)" (onHide)="handleDialogClose()">

    <p-messages key="checkMsg" [style]="{
        'font-size': '12px'
      }"></p-messages>

      <ng-template pTemplate="header">
        <div class="p-d-flex p-jc-between p-ai-center">
            <div [style]="{margin:'0 0 0 550px', display: 'flex'}">
                <p-button *ngIf="headerName === 'All Appointments'" icon="pi pi-download" label="Export" class="p-button-sm p-button-secondary" 
                    (click)="exportAppointments('month_appointments')" style="margin-right: 10px;"></p-button>
                <p-button icon="pi pi-print" label="Print" class="p-button-sm p-button-secondary" 
                    (click)="printPage('printableArea')"></p-button>
            </div>
        </div>
    </ng-template>

    <div class="section" *ngIf="headerName === 'Client Appointments' && !idvalue">
        <h3 class="section-header">Client List</h3>
        <div class="search-box">
            <input type="text" pInputText [(ngModel)]="searchText" placeholder="Search by name or last 4 digits of mobile number" />
        </div>
        <p-table [value]="filterByPhoneNumber(searchText)" [autoLayout]="true" [responsive]="true" [rowHover]="true" selectionMode="single" [(selection)]="idvalue" [paginator]="true" [paginatorPosition]="'top'"
        [rows]="rows"
        [first]="first"
        [rowsPerPageOptions]="rowsPerPageOptions"
        (onPage)="onPageChange($event)"
        [style]="{'border': '1px solid #2196F3'}">
            <ng-template pTemplate="header">
                <tr >
                    <th [style]="{'background-color': '#2196F3', color: '#fff'}">First Name</th>
                    <th [style]="{'background-color': '#2196F3', color: '#fff'}">Last Name</th>
                    <th [style]="{'background-color': '#2196F3', color: '#fff'}">Mobile Number</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-client>
                <tr [pSelectableRow]="client" [class.selected]="client === selectedClient" (click)="onClientChange(client)">
                    <td>{{ client.firstName }}</td>
                    <td>{{ client.lastName }}</td>
                    <td>{{ client.mobileNo }}</td>
                </tr>
            </ng-template>
              
            <ng-template pTemplate="paginatorLeft">
                <div>Results {{first + 1}} - {{first + rows}} of {{filterByPhoneNumber(searchText).length}}</div>
            </ng-template>

        </p-table>
    </div>
    
    <div id="printableArea" *ngIf="headerName != 'Client Appointments' || (headerName === 'Client Appointments' && idvalue) ">
        <p-table 
        [value]="headerName === 'All Appointments'? INITIAL_EVENTS : SELECTED_EVENTS"
        styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true'
        [responsive]='true' [rowHover]="true"
        [style]="{'border': '1px solid #2196F3'}"
        [paginator]="true"
        [rows]="5"               
        [rowsPerPageOptions]="[5, 10, 20, 50]"
        [sortField]="'start'"
        [sortOrder]="1"
        [paginatorPosition]="'top'">
           <ng-template pTemplate="header">
               <tr>
                   <th [style]="{'background-color': '#2196F3', color: '#fff'}">Appointment Id</th>
                   <th [style]="{'background-color': '#2196F3', color: '#fff'}">Service Name</th>
                   <th [style]="{'background-color': '#2196F3', color: '#fff'}">Client Name</th>
                   <th [style]="{'background-color': '#2196F3', color: '#fff'}">Provider</th>
                   <th [style]="{'background-color': '#2196F3', color: '#fff'}">Start Date/Time</th>
                   <th [style]="{'background-color': '#2196F3', color: '#fff'}">End Date/Time</th>
               </tr>
           </ng-template>
           <ng-template pTemplate="body" let-list>
               <tr *ngIf="!list.allDay" (click)="headerName === 'Client Appointments' ? viewEdit(list) : null"
               [title]="headerName === 'Client Appointments' ? 'Click to edit client appointments' : ''">
                   <td>{{list.id}}</td>
                   <td>{{list.title}}</td>
                   <td>{{list.clientName}}</td>
                   <td>{{list.serviceProvider}}</td>
                   <td>{{list.start  | date: 'MM-dd-yyyy h:mm a'}}</td>
                   <td>{{list.end  | date: 'MM-dd-yyyy h:mm a'}}</td>
               </tr>
           </ng-template>
       </p-table>
    </div>
   

    <ng-template pTemplate="footer">
        <!-- <p-button icon="pi pi-trash" label="Remove" class="p-button-danger"
            (click)="confirmAddEditDialog('add_appointment')" [disabled]="checkDisabled">
        </p-button> -->

    </ng-template>
</p-dialog>

<!-- SHOW AVAILABLE SLOTS TIMES -->
 <p-dialog #addDialog header="Available Slots" [(visible)]="visibleAvailableSlots" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" styleClass="p-fluid" [contentStyle]="{'overflow':'visible'}" [style]="{'overflow':'auto', width: '30vw'}"
    (onShow)="showDialogMaximized($event,addDialog)">
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
    <p-messages key="checkMsg" [style]="{
        'font-size': '12px'
      }"></p-messages>

    
    <p-table [value]="availableslots"
    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true'
    [responsive]='true' [rowHover]="true">
        <ng-template pTemplate="header">
            <tr>
                <th>Available slots</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-list>
            <tr>
                <td>{{list}}</td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>

<!-- ADD BLOCK -->
<p-dialog #blockDialog [header]="headerName" [(visible)]="displayBlockModal" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" styleClass="p-fluid" [contentStyle]="{'overflow':'visible'}"
    (onShow)="showDialogMaximized($event,blockDialog)">
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
    <p-messages key="checkMsg" [style]="{
        'font-size': '12px'
      }"></p-messages>
    <form role="form" #blockSlotForm="ngForm" style="min-width: 50vw;">
        <div class="row">
            <div class="form-group col-md-6">
                <label for="startTime">Appointment Start Date/Time<span class="symbol required"></span></label>
                <p-calendar [(ngModel)]="appointmentStartDateTime" name="appointmentStartDateTime" inputId="startTime"
                    [showTime]="true" hourFormat="12" [showButtonBar]="true" [showIcon]="true" [readonlyInput]="true"
                    placeholder="Start date/time" [disabled]="slotDisabled"
                    (onClose)="onDateChange($event,'add_block_slots')">
                </p-calendar>

            </div>
            <div class="form-group col-md-6">
                <label for="endTime">Appointment End Date/Time<span class="symbol required"></span></label>
                <p-calendar [(ngModel)]="appointmentEndDateTime" name="appointmentEndDateTime" inputId="endTime"
                    [showTime]="true" hourFormat="12" [showButtonBar]="true" [showIcon]="true" [readonlyInput]="true"
                    placeholder="End date/time" (onClose)="onDateChange($event,'add_block_slots')"
                    [disabled]="slotDisabled">
                </p-calendar>

            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Comments<span class="symbol required"></span></label>
                <textarea pInputTextarea type="text" name="blockNotes" class="form-control"
                    placeholder="Enter comments...." [(ngModel)]="blockComment" [disabled]="slotDisabled"></textarea>
            </div>
        </div>
    </form>

    <ng-template pTemplate="footer">
        <p-button *ngIf="headerName=='Add Block Slots'" icon="pi pi-ban" label="Block" (click)="addBlockSlot()"
            [disabled]="checkDisabled">
        </p-button>
        <p-button *ngIf="headerName=='View Block Slots'" icon="pi pi-check-circle" label="Unblock"
            (click)="unlockSlot()" [disabled]="disabled">
        </p-button>
    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>

<!-- CONFIRMATION MODAL -->
<p-confirmDialog #cd [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" [style]="{'min-width':'45vw'}">
    <p-footer>
        <div class="p-d-flex p-ai-center p-pl-2" *ngIf="!isReminder">
            <p-checkbox [(ngModel)]="sendNotification" [binary]="true" inputId="binary"
                [disabled]="disabledSendNotification"></p-checkbox>
            <label for="binary" class="p-ml-3 p-mb-0">Send notification to customer.</label>
        </div>
        <div class="p-mb-3 p-mt-3">
            <p-messages *ngIf="twillioNumber == null" severity="warn">
                <ng-template pTemplate>
                    <div class="p-d-flex p-ai-center">
                        <div class="p-ml-2" style="font-size: 12px;">Unable to send notification. SMS number
                            provisioning is in progress</div>
                    </div>
                </ng-template>
            </p-messages>
        </div>
        <div *ngIf="false" class="p-mb-3 p-mt-3">
            <p-messages *ngIf="isUserNonchatstasy && sendNotification" severity="warn">
                <ng-template pTemplate>
                    <div class="p-d-flex p-ai-center">
                        <div class="p-ml-2" style="font-size: 12px;">1 Message Credit will be deducted</div>
                    </div>
                </ng-template>
            </p-messages>
        </div>
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-button-danger" 
            [label]="visibleEditAppoinment ? 'Cancel' : 'No'" 
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="pi pi-check" 
            [label]="visibleEditAppoinment ? 'Confirm' : 'Yes'" 
            (click)="cd.accept()"></button>
    </p-footer>
</p-confirmDialog>

<!-- TEST APPOINTMENT MODAL -->
<p-dialog #addDialog [header]="headerName" [(visible)]="visibleTestAppointment" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" styleClass="p-fluid" [contentStyle]="{'overflow':'visible'}"
    (onShow)="showDialogMaximized($event,addDialog)"
    [style]="{ overflow: 'auto' }">
    <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
    <p-messages key="checkMsg" [style]="{
        'font-size': '12px'
      }"></p-messages>

    
    <p-table [value]="appointmentList"
    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true'
    [responsive]='true' [rowHover]="true"
    [paginator]="true"
    [rows]="5"               
    [rowsPerPageOptions]="[5, 10, 20, 50]"
    [paginatorPosition]="'top'">
        <ng-template pTemplate="header">
            <tr>
                <th>Select</th>
                <th>Appointment Id</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Scheduled On From</th>
                <th>Scheduled On To</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-list>
            <tr>
                <td>
                    <p-checkbox [(ngModel)]="checked" [binary]="true" inputId="binary" [disabled]="true"></p-checkbox>
                </td>
                <td>{{list.appointmentId}}</td>
                <td>{{list.clientData?.firstName}}</td>
                <td>{{list.clientData?.lastName}}</td>
                <td>{{list.scheduledOnFrom  | date: 'MM-dd-yyyy h:mm a'}}</td>
                <td>{{list.scheduledOnTo  | date: 'MM-dd-yyyy h:mm a'}}</td>
            </tr>
        </ng-template>
    </p-table>

    <ng-template pTemplate="footer">
        <!-- <p-button icon="pi pi-trash" label="Remove" class="p-button-danger"
            (click)="confirmAddEditDialog('add_appointment')" [disabled]="checkDisabled">
        </p-button> -->
        <button pButton type="button" label="Remove" class="p-button-danger" icon="pi pi-trash" (click)="removeTestAppointment()"></button>

    </ng-template>
    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>

<!-- NEW ADD APPOINTMENT -->
<p-dialog #addDialog [header]="headerName" [(visible)]="visibleAddNewAppoinment" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="true" styleClass="p-fluid custom-dialog" [contentStyle]="{ overflow: 'visible' }" [style]="{ overflow: 'auto', width: '80vw' }" (onShow)="showDialogMaximized($event, addDialog)" (onHide)="toggleButton()">
    <p-messages key="errMsg" [style]="{ 'font-size': '12px' }"></p-messages>
    <p-messages key="checkMsg" [style]="{ 'font-size': '12px' }"></p-messages>

    <div class="section">
        <p style="display: flex; justify-content: flex-end;">Date: {{ selectedDate }}</p>
        <h3 class="section-header">Service Type</h3>
        <div class="dropdown-row">
            <p-dropdown [options]="newServiceTypeFilterList" [(ngModel)]="serviceType" optionLabel="value" optionValue="name" [filter]="true" placeholder="Select Service Type" (onChange)="onServiceTypeChange($event, 'modal')" [style]="{margin: '0 10px 0 0'}"></p-dropdown>
            <p-dropdown *ngIf="serviceProviderFilterList" [options]="serviceProviderFilterList" [(ngModel)]="serviceProvider" optionLabel="value" optionValue="name" [filter]="true" (onChange)="onServiceProviderChange($event)" [style]="{margin: '0 10px'}"></p-dropdown>
            <p-dropdown [options]="AvailableSlotsForProvider" [(ngModel)]="selectedSlot" optionLabel="value" optionValue="name" [filter]="true" placeholder="Select Slot" (onChange)="onSlotTimeClick($event)" [style]="{margin: '0 0 0 10px'}"></p-dropdown>
        </div>
    </div>

    <div class="section">
        <h3 class="section-header">Client List</h3>
        <div class="search-box">
            <input type="text" pInputText [(ngModel)]="searchText" placeholder="Search by name or last 4 digits of mobile number" />
        </div>
        <p-table [value]="filterByPhoneNumber(searchText)" [autoLayout]="true" [responsive]="true" [rowHover]="true" selectionMode="single" [(selection)]="selectedClient" [paginator]="true"
        [rows]="rows"
        [first]="first"
        [rowsPerPageOptions]="rowsPerPageOptions"
        (onPage)="onPageChange($event)"
        [style]="{'border': '1px solid #2196F3'}">
            <ng-template pTemplate="header">
                <tr >
                    <th [style]="{'background-color': '#2196F3', color: '#fff'}">First Name</th>
                    <th [style]="{'background-color': '#2196F3', color: '#fff'}">Last Name</th>
                    <th [style]="{'background-color': '#2196F3', color: '#fff'}">Mobile Number</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-client>
                <tr [pSelectableRow]="client" [class.selected]="client === selectedClient" (click)="onClientRowSelect(client)">
                    <td>{{ client.firstName }}</td>
                    <td>{{ client.lastName }}</td>
                    <td>{{ client.mobileNo }}</td>
                </tr>
            </ng-template>
              
            <ng-template pTemplate="paginatorLeft">
                <div>Results {{first + 1}} - {{first + rows}} of {{filterByPhoneNumber(searchText).length}}</div>
            </ng-template>

        </p-table>
    </div>

    <p-footer>
        <button pButton label="OK" (click)="confirmAddEditDialog('add_appointment')"></button>
    </p-footer>
</p-dialog>

<!-- NEW EDIT APPOINTMENT -->
<p-dialog #editDialog [header]="headerName" [(visible)]="visibleEditAppoinment" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="true" styleClass="p-fluid custom-dialog" [contentStyle]="{ overflow: 'visible' }" [style]="{ overflow: 'auto', width: '80vw' }" (onShow)="showDialogMaximized($event, editDialog)">
    <p-messages key="errMsg" [style]="{ 'font-size': '12px' }"></p-messages>
    <p-messages key="checkMsg" [style]="{ 'font-size': '12px' }"></p-messages>

    <div class="section">
        <p style="display: flex; justify-content: flex-end;">Date: {{selectedDate | date:
            'MM-dd-yyyy'}}</p>
        <div>
            <h3 class="section-header">Client</h3> 
            <span style="color: #4f4f4f;align-items: center;
            display: flex;">
                <span
                    *ngIf="singleClientData.firstName != 'null'">{{singleClientData.firstName}}&nbsp;</span>
                <span *ngIf="singleClientData.middleName != 'null'">{{singleClientData.middleName}}
                    &nbsp;</span>
                <span *ngIf="singleClientData.lastName != 'null'">{{singleClientData.lastName}}</span>
            </span>
        </div><br>
        <div>
            <h3 class="section-header">Service Details</h3> 
            <span style="color: #4f4f4f; display: flex; ">
                <div style="margin-right: 50px;"><h5><b>Service name</b></h5><p *ngIf="newServiceType != null">{{newServiceType.value}}</p></div>
                <div><h5><b>Service Provider</b></h5><p *ngIf="newServiceProvider != null">{{newServiceProvider.value}}</p></div>
            </span>
        </div><br>
        <h3 class="section-header">Slot Details</h3>
        <div class="dropdown-row">
            <p-dropdown [options]="AvailableSlotsForProvider" [(ngModel)]="selectedSlot" optionLabel="value" optionValue="name" [filter]="true" placeholder="Select Slot" (onChange)="onSlotTimeClick($event)" [style]="{margin: '0 0 0 10px', width: '300px'}"></p-dropdown>
        </div>
    </div>

    <div class="section">
        <h3 class="section-header">Special Instruction</h3>
        <div class="form-group">
            <textarea pInputTextarea type="text" class="form-control" name="specialInstruction" placeholder="Enter Special Instruction..." [(ngModel)]="specialInstruction" maxlength="500"></textarea>
            <span class="remaining">{{ specialInstruction?.length }}/500</span>
        </div>
    </div>

    <p-footer>
        <button pButton label="Save Changes" (click)="confirmAddEditDialog('edit_appointment')" [disabled]="checkDisabled"></button>
    </p-footer>

    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>



<!-- VIEW APPOINTMENT -->
<p-sidebar [(visible)]="visibleViewAppointment" position="right" [baseZIndex]="5000" [style]="{overflow:'auto'}"
    styleClass="different">
    <div *ngIf="!isLoading">
        <div class="p-p-2">
            <h5 class="sidebar_title" *ngIf="appointmentNumber != 'null'"><span>{{appointmentNumber}}</span>
            </h5>
            <ul class="sidebar_ul">
                <li class="list_sidebar">
                    <div class="sidebar_container" style="border-bottom: 1px solid #eee;">
                        <div class="sidebar_info mb-2">
                            <span style="color: #919191;align-items: center;
                            display: flex;"><i class="ti-id-badge p-mr-2">
                                </i>
                                <span
                                    *ngIf="singleClientData.firstName != 'null'">{{singleClientData.firstName}}&nbsp;</span>
                                <span *ngIf="singleClientData.middleName != 'null'">{{singleClientData.middleName}}
                                    &nbsp;</span>
                                <span *ngIf="singleClientData.lastName != 'null'">{{singleClientData.lastName}}</span>
                            </span>
                        </div>
                        <div *ngIf="singleClientData.mobileNo != 'null'" class="sidebar_info mb-2">
                            <span style="color: #919191;align-items: center;
                            display: flex;"><i class="ti-mobile p-mr-2">
                                </i>{{singleClientData.mobileNo}}</span>
                        </div>
                        <div *ngIf="singleClientData.email != 'null'" class="sidebar_info"><span style="color: #919191;align-items: center;
                            display: flex;"><i class="ti-email p-mr-2"></i><a href="javascript:void(0)"
                                    style="text-decoration: underline !important;">{{singleClientData.email}}</a></span>
                        </div>
                    </div>

                    <div *ngIf="appointmentStartDateTime != 'null'" class="sidebar_container">
                        <div class="sidebar_info">
                            <h4 class="sidebar_info_title mb-2">Scheduled On</h4>
                            <span style="color: #919191;align-items: center;
                            display: flex;"><i class="pi pi-calendar p-mr-2"></i>{{appointmentStartDateTime | date:
                                'MM-dd-yyyy h:mm a'}} - {{appointmentEndDateTime | date:
                                'MM-dd-yyyy h:mm a'}}</span>
                        </div>
                    </div>
                    <!-- <div class="sidebar_container">
                        <div class="sidebar_info p-grid p-fluid" style="display: flex;">
                            <div class="p-col-6">
                                <span style="color: #919191;align-items: center;
                                display: flex;"><i class="pi pi-clock p-mr-2"></i>12:40</span>
                            </div>
                            <div class="p-col-6">
                                <span style="color: #919191;align-items: center;
                            display: flex;"><i class="pi pi-clock p-mr-2"></i>01:40</span>
                            </div>
                        </div>
                    </div> -->

                    <div *ngIf="serviceTypeName != 'null'" class="sidebar_container"
                        style="border-top: 1px solid #eee;">
                        <div class="sidebar_info">
                            <h4 class="sidebar_info_title mb-2">Service Type</h4>
                            <span style="color: #919191">{{serviceTypeName}}</span>
                        </div>
                    </div>
                    <div *ngIf="serviceProviderName != 'null'" class="sidebar_container">
                        <div class="sidebar_info">
                            <h4 class="sidebar_info_title mb-2">Provided by</h4>
                            <span style="color: #919191">{{serviceProviderName}}</span>
                        </div>
                    </div>
                    <div *ngIf="specialInstruction != 'null' && specialInstruction != ''" class="sidebar_container">
                        <div class="sidebar_info">
                            <h4 class="sidebar_info_title mb-2">Special Instruction</h4>
                            <span style="color: #919191">{{specialInstruction}}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>


        <div class="p-p-2" style="border-top: 1px solid #eee;width:100%; display: flex;">
            <div>
                <button pButton pRipple icon="pi pi-bell" label="Send Reminder"
                    class="p-button-raised p-button-success p-button-sm" (click)="showHideModalDialog('send_reminder')"
                    style="font-size: 12px;margin-left:0.5rem" [disabled]="view_disabled || bussinesInfo.state=='offline'">
                </button>
                <button *ngIf="getPrivilege('manage_appointment')" pButton pRipple icon="pi pi-pencil" label="Edit"
                    class="p-button-raised p-button-sm" style="font-size: 12px;margin-left:0.5rem"
                    (click)="showHideModalDialog('edit_appointment')" [disabled]="view_disabled">
                </button>
            </div>
            <div style="display: flex; flex-direction: column;">
                <button pButton pRipple icon="pi pi-check-circle"
                    label="Confirm Appointment" class="p-element p-ripple p-button-success p-button-text p-button p-component" 
                    (click)="confirmAddEditDialog('confirm_appointment_payment')"
                    style="font-size: 12px;margin-left:0.5rem" 
                    *ngIf="showConfirmButton">
                </button>
                <button pButton pRipple icon="pi pi-trash" (click)="showHideModalDialog('cancel_appointment')"
                    label="Cancel Appointment" class="p-button-danger p-button-text"
                    style="font-size: 12px;margin-left:0.5rem" [disabled]="view_disabled || bussinesInfo.state=='offline'">
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="isLoading" class="p-p-2 p-mr-2">
        <div style="margin: 15px 10px 10px 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'22px'" [width]="'100%'"></ngx-shimmer-loading>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'94px'"></ngx-shimmer-loading>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'90px'"></ngx-shimmer-loading>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'79px'"></ngx-shimmer-loading>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'104px'"></ngx-shimmer-loading>
        </div>
        <div class="p-grid p-fluid" style="padding: 10px;">
            <div class="p-col-6">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'14px'" [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading [shape]="'rect'" [height]="'17px'" [width]="'100%'"></ngx-shimmer-loading>
            </div>
            <div class="p-col-6">
                <ngx-shimmer-loading [shape]="'rect'" [height]="'14px'" [width]="'100%'"></ngx-shimmer-loading>
                <ngx-shimmer-loading [shape]="'rect'" [height]="'17px'" [width]="'100%'"></ngx-shimmer-loading>
            </div>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'49px'"></ngx-shimmer-loading>
        </div>
        <div style="padding: 10px;">
            <ngx-shimmer-loading [shape]="'rect'" [height]="'35px'" [width]="'81px'"></ngx-shimmer-loading>
        </div>
    </div>
</p-sidebar>

<!-- IMPORT APPOINTMENT MODAL -->

<p-dialog #inserAppointment header="Upload Appointments" [(visible)]="displayImportModal" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" [maximizable]="true" (onHide)="clearFormData()"
    (onShow)="showDialogMaximized($event,inserAppointment)">
    <form style="min-width: 60vw;max-width: 90vw;" role="form" #importAppointmentForm="ngForm">
        <div class="file_upload_container">
            <div class="p-d-flex p-jc-between">
                <div class="p-d-flex">
                    <input type="file" #fileInput class="file-input" accept=".csv, .xls, .xlsx, .xlsm"
                        (change)="addFiles($event)" />
                    <button pButton pRipple type="button" icon="pi pi-upload"
                        class="p-button-rounded p-button-raised p-ml-2" (click)="importAppointmentExcelPreview(fileInput)"
                        [disabled]="uploadedFiles.length == 0"></button>
                </div>

                <p-button label="Download template" styleClass="p-button-link p-ml-2" (click)="downloadTemplate()">
                </p-button>
            </div>
            <div class="p-pt-1">Upload csv, xls, xlsx ,xlsm only</div>
        </div>
        <div class="file_upload_content">
            <p-progressBar *ngIf="isLoading" mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
            <ul *ngIf="uploadedFiles.length != 0" class="p-mt-2">
                <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
            </ul>
            <p-messages key="errMsg" [style]="{
                'font-size': '12px'
              }"></p-messages>
            <p-table *ngIf="errorDataList.length != 0" #dt1 [value]="errorDataList"
                styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [responsive]='true'
                [rowHover]="true" [scrollable]="true" scrollHeight="155px" [autoLayout]='true'>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:50px;">S.no.</th>
                        <th>First Name</th>
                        <th>Middle Name</th>
                        <th>Last Name</th>
                        <th style="width: 120px;">Mobile Number</th>
                        <th>Service Name</th>
                        <th>Start Date/Time</th>
                        <th>Service Provider</th>
                        <th *ngIf="numErrorRecords != 0">Result Message</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <td style="width:50px;">{{data.row}}</td>
                        <td style="overflow: hidden;text-overflow: ellipsis;">{{data.firstName}}</td>
                        <td style="overflow: hidden;text-overflow: ellipsis;">{{data.middleName}}</td>
                        <td style="overflow: hidden;text-overflow: ellipsis;">{{data.lastName}}</td>
                        <td style="width: 120px;overflow: hidden;text-overflow: ellipsis;">{{data.mobileNo}}</td>
                        <td style="overflow: hidden;text-overflow: ellipsis;">{{data.serviceType}}</td>
                        <td style="overflow: hidden; text-overflow: ellipsis;">{{ data.startTime | date:'MM/dd/yyyy - HH:mm' }}</td>
                        <td style="overflow: hidden;text-overflow: ellipsis;">{{data.serviceProvider}}</td>
                        <td *ngIf="numErrorRecords != 0">{{data.resultMessage}}</td>
                    </tr>
                </ng-template>
            </p-table>
            <div *ngIf="noOfData > 25 && errorFileName != null" class="p-d-flex p-jc-end">
                <p-button [label]="'...'+ (noOfData - 25) +' more'" styleClass="p-button-link p-button-sm p-ml-2"
                    >
                </p-button>
            </div>
 
        </div>

    </form>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="displayImportModal = false" label="Cancel"
            styleClass="p-button-danger p-button-text"></p-button>
        <p-button icon="pi pi-save" (click)="importAppointments()" label="Save" [disabled]="importDisabled">
        </p-button>
    </ng-template>
    <div *ngIf="isLoading && errorDataList.length != 0" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>

<!-- EARLIEST APPOINTMENT MODAL -->
<p-dialog #addDialog [(visible)]="visibleEarliestAppointment" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="true" styleClass="p-fluid" [contentStyle]="{'overflow':'visible'}"
    (onShow)="showDialogMaximized($event,addDialog)"  [closable]="false">
    
    <!-- Custom header with buttons and title centered -->
    <ng-template pTemplate="header">
        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
            <button pButton icon="pi pi-chevron-left" label="Previous" style="width: 120px; margin-right: 10px;" (click)="previousAppointment()"
            [disabled]="currentAppointmentIndex === 0"></button>
            <span style="flex-grow: 1; text-align: center; font-weight: 600; font-size: 1.25rem;">{{ headerName }}</span>
            <button pButton icon="pi pi-chevron-right" label="Next" style="width: 120px; margin-left: 10px;" (click)="nextAppointment()"
            [disabled]="currentAppointmentIndex === appointments.length - 1"></button>
        </div>
    </ng-template>

    <p-messages key="errMsg" [style]="{'font-size': '12px'}"></p-messages>
    <p-messages key="checkMsg" [style]="{'font-size': '12px'}"></p-messages>

    <form role="form" #addAppointmentForm="ngForm" style="min-width: 60vw;">
        <div class="row">
            <div class="form-group col-md-4">
                <label>Client<span class="symbol required"></span></label>
                <p-dropdown name="client" [options]="clients" [(ngModel)]="client" optionLabel="name"
                    optionValue="value" [filter]="true" [style]="{height: '38px','font-size':'14px'}"
                    placeholder="Select Client" [panelStyle]="{'font-size':'14px'}"></p-dropdown>
            </div>
            <div class="form-group col-md-4">
                <label>Service Type<span class="symbol required"></span></label>
                <p-dropdown [options]="serviceTypeFilterList" [(ngModel)]="selectedServiceType"
                                optionLabel="value" optionValue="name" [showClear]="true"
                                (onChange)="onFilterEarlyValueChange($event, 'serviceType')" [style]="{'font-size':'14px'}"
                                placeholder="Service Name"></p-dropdown>
            </div>
            <div class="form-group col-md-4">
                <label>Service Provider<span class="symbol required"></span></label>
                <p-dropdown [options]="serviceProviderFilterList" [(ngModel)]="selectedServiceProvider"
                                optionLabel="value" optionValue="name" [showClear]="true"
                                (onChange)="onFilterEarlyValueChange($event, 'serviceProvider')" [panelStyle]="{'font-size':'14px'}"
                                placeholder="Service Provider">
                            </p-dropdown>
            </div>
        </div>
        <div style="display: flex; flex-direction: column; align-items: center;">
            <label>Appointment Date and Time</label>
            <h4>{{ currentAppointmentDisplay }}</h4>
        </div>
    </form>

    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" label="Cancel" (onClick)="resetDialogValues()"
            styleClass="p-button-danger p-button-text"></p-button>
        <p-button icon="pi pi-save" label="Confirm" (onClick)="onClickSaveEaliest()">
        </p-button>
    </ng-template>

    <div *ngIf="isLoading" class="progressLayout">
        <div class="progressContainer">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
        </div>
    </div>
</p-dialog>


<!-- TOAST -->
<p-toast position="bottom-right" key="br"></p-toast>